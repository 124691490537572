import * as permissions from '../config/permissions';

const menuItems = {
    items: [
        {
            id: 'resources',
            title: 'recursos',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'accounts',
                    title: 'Contas',
                    type: 'collapse',
                    icon: 'feather icon-message-circle',
                    children: [
                        {
                            id: 'create-account-wa',
                            title: 'Whatsapp',
                            type: 'item',
                            url: '/accounts/whatsapp',
                            icon: 'si si-whatsapp',
                            breadcrumbs: false,
                            permissions: [permissions.WHATSAPP_ACCOUNTS.READ],
                        },
                        {
                            id: 'create-account-tg',
                            title: 'Telegram',
                            type: 'item',
                            url: '/accounts/telegram',
                            icon: 'feather icon-navigation',
                            breadcrumbs: false,
                            permissions: [permissions.TELEGRAM_ACCOUNTS.READ],
                        },
                        {
                            id: 'create-account-wc',
                            title: 'Webchat',
                            type: 'item',
                            url: '/accounts/webchat',
                            icon: 'si si-wechat',
                            breadcrumbs: false,
                            permissions: [permissions.WEBCHAT_ACCOUNTS.READ],
                        },
                    ],
                },
                {
                    id: 'atendimento',
                    title: 'Atendimento',
                    type: 'collapse',
                    icon: 'feather icon-phone-call',
                    children: [
                        {
                            id: 'create-atendimento',
                            title: 'Criar Central',
                            type: 'item',
                            url: '/attendance/add',
                            icon: 'feather icon-plus',
                            breadcrumbs: false,
                            permissions: [permissions.ATTENDANCES.CREATE],
                        },
                        {
                            id: 'attendance-view',
                            type: 'dynamic-list',
                            src: 'attendance/',
                            baseUrl: '/advanced-chat',
                            icon: 'feather icon-phone-call',
                            breadcrumbs: false,
                            permissions: [permissions.ATTENDANCES.READ],
                        },
                    ],
                },
                {
                    id: 'integrations',
                    title: 'Integrações',
                    type: 'collapse',
                    icon: 'feather icon-zap',
                    children: [
                        {
                            id: 'integration-chatbot-rasa',
                            title: 'Assistente Virtual RASA',
                            type: 'item',
                            url: '/integrations/rasa',
                            icon: 'feather icon-message-square',
                            breadcrumbs: false,
                            permissions: [permissions.RASA_INTEGRATIONS.READ],
                        },
                    ],
                },
                {
                    id: 'campaings',
                    title: 'Campanhas',
                    type: 'collapse',
                    icon: 'feather icon-target',
                    children: [
                        {
                            id: 'view-contacts',
                            title: 'Contatos',
                            type: 'item',
                            url: '/contacts',
                            icon: 'feather icon-user-plus',
                            breadcrumbs: false,
                            permissions: [permissions.CONTACTS.READ],
                        },
                        {
                            id: 'view-contacts-lists',
                            title: 'Listas',
                            type: 'item',
                            url: '/contact_lists',
                            icon: 'feather icon-users',
                            breadcrumbs: false,
                            permissions: [permissions.CONTACT_LISTS.READ],
                        },
                        {
                            id: 'view-messages',
                            title: 'Mensagens',
                            type: 'item',
                            url: '/campaigns',
                            icon: 'feather icon-message-circle',
                            breadcrumbs: false,
                            permissions: [permissions.MESSAGE_CAMPAIGNS.READ],
                        },
                    ],
                },
                // {
                //     id: 'reports',
                //     title: 'Relatórios',
                //     type: 'collapse',
                //     icon: 'feather icon-pie-chart',
                //     children: [
                //         {
                //             id: 'reports-analytics',
                //             title: 'Relatórios Analíticos',
                //             type: 'item',
                //             url: '/reports/view',
                //             icon: 'feather icon-bar-chart',
                //             breadcrumbs: false,
                //             permissions: [permissions.REPORTS.READ],
                //         },
                //     ],
                // },

            ],
        },
    ],
};

export default menuItems;
